<template>
  <section class="box-height" style="margin-bottom: 70px">
    <div
      class="box-title"
      style="padding-bottom: 150px; border: 1px #e5e5e5 solid"
    >
      <el-row :gutter="24" v-loading="rowLoading">
        <el-col :span="24" class="customTag">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane name="城市">
              <span class="tagW" slot="label">城市</span>
            </el-tab-pane>
            <el-tab-pane name="职能">
              <span class="tagW" slot="label">职能</span>
            </el-tab-pane>
            <el-tab-pane name="行业">
              <span class="tagW" slot="label">行业</span>
            </el-tab-pane>
          </el-tabs>
          <el-button
            type="default"
            plain
            size="mini"
            class="commonBtn"
            @click="addNew"
            v-has="'setting:customfield:add'"
          >
            <i class="el-icon-plus"></i>
            新增{{ activeName }}根节点
          </el-button>
        </el-col>
        <el-col :span="24" class="tree-box">
          <p class="DialogTips tip">
            <i class="el-icon-warning"></i>右击可进行编辑、添加、删除操作
          </p>
          <el-tree
            :data="setTree"
            :props="defaultProps"
            node-key="id"
            ref="SlotMenuList"
            @node-contextmenu="rihgtClick"
            class="tip"
          >
            <span class="slot-t-node" slot-scope="{ node, data }">
              <span :style="data.editable||activeName !== '城市' ? '' : 'color:#999'">
                <i
                  v-show="!data.children || data.children.length == 0"
                  style="margin-right: 10px"
                ></i>
                <span>
                  {{ node.label }}
                  <a v-if="data.type == '集团'">(集团)</a>
                  <a v-else-if="data.type == '地区'">(地区)</a>
                  <a v-else-if="data.type == '团队'">(团队)</a>
                  <a v-else-if="data.type == '子团队'">(子团队)</a>
                </span>
              </span>
            </span>
          </el-tree>
          <!--鼠标右键点击出现页面-->
          <el-menu
            id="rightClickMenu"
            v-show="menuVisible"
            class="el-menu-vertical"
            @select="handleRightSelect"
            active-text-color="#333"
            text-color="#333"
          >
            <el-menu-item
              index="1"
              class="menuItem"
              v-has="'setting:customfield:add'"
            >
              <span slot="title">添加子节点</span>
            </el-menu-item>
            <el-menu-item
              index="2"
              class="menuItem"
              v-has="'setting:customfield:edit'"
            >
              <span slot="title">修改节点</span>
            </el-menu-item>
            <el-menu-item
              index="3"
              class="menuItem"
              v-has="'setting:customfield:del'"
            >
              <span slot="title">删除节点</span>
            </el-menu-item>
          </el-menu>
        </el-col>
      </el-row>
    </div>

    <el-dialog
      :title="titName"
      :visible.sync="dialogVisible"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      append-to-body
      width="600px"
      :before-close="handleClose"
    >
      <el-form
        @submit.native.prevent
        :model="createDate"
        ref="createDate"
        :rules="rules"
        label-width="90px"
      >
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item :label="activeName=='城市'?'行政名称：':'节点名称：'" prop="name" :rules="[{ required: true, message: activeName=='城市'?'请输入行政名称':'请输入节点名称', trigger: 'blur'}]">
              <el-input
                v-model.trim="createDate.name"
                maxlength="50"
                placeholder="请不要输入超过50个字"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="default"
          plain
          size="mini"
          @click="
            dialogVisible = false;
            resetForm('createDate');
          "
          >取消</el-button
        >
        <el-button
          type="primary"
          size="mini"
          @click="save('createDate')"
          :loading="SaveLoading"
          >保存</el-button
        >
      </span>
    </el-dialog>
  </section>
</template>
<script>
import {
  getCommonColumnList,
  addOrEditCustom,
  deleteCustom,
  getCustomById,
  cityTree, //城市选项树
  addOrEditcityTree, //新增或编辑城市选项树
  cityDetail, //城市详情
  cityDelete, //删除城市
} from "../../api/api";
export default {
  data() {
    return {
      rowLoading: false,
      SaveLoading: false,
      activeName: "城市",
      createDate: {
        id: "",
        name: "",
        parentId: 0,
        type: "",
        version: 0,
        shortName: "",
      },
      menu: document.querySelector("#rightClickMenu"),
      rules: {
        name: [
          {
            required: true,
            trigger: "blur",
            // message: this.activeName=='城市'?"请输入行政名称":"请输入节点名称",
          },
        ],
      },
      titName: "",
      dialogVisible: false,
      setTree: [], //节点树数据
      defaultProps: {
        children: "children",
        label: "name",
      },
      menuVisible: false,
      objectID: null,
    };
  },
  methods: {
    addNew() {
      this.titName = "新增根节点";
      this.dialogVisible = true;
      this.createDate.id = "";
      this.createDate.parentId = 0;
      this.createDate.type = this.activeName;
    },
    handleClick(tab) {
      this.activeName = tab.name;
      this.menuVisible = false;
      this.objectID = null;
      this.loadList(tab.name);
    },
    save(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.SaveLoading = true;
          let fcn = null;
          if (this.activeName == "城市") {
            this.createDate.code = "";
            fcn = addOrEditcityTree;
          } else {
            fcn = addOrEditCustom;
          }
          fcn(this.createDate).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.dialogVisible = false;
              this.resetForm("createDate");
              this.loadList(this.activeName);
            }
            this.SaveLoading = false;
          });
        } else {
          return false;
        }
      });
    },
    handleRightSelect(key) {
      if (key == 1) {
        this.NodeAdd(this.NODE, this.DATA);
        this.menuVisible = false;
      } else if (key == 2) {
        this.NodeEdit(this.NODE, this.DATA);
        this.menuVisible = false;
      } else if (key == 3) {
        this.NodeDel(this.NODE, this.DATA);
        this.menuVisible = false;
      }
    },
    rihgtClick(event, object, value, element) {
      if (object.editable||this.activeName !== '城市') {
        if (this.objectID !== object.id) {
          this.objectID = object.id;
          this.menuVisible = true;
          this.NODE = value;
        } else {
          this.menuVisible = !this.menuVisible;
        }
        this.DATA = object;
        document.addEventListener("click", (e) => {
          this.menuVisible = false;
        });
        /* 菜单定位基于鼠标点击位置 */
        element.$el.append(this.menu);
        this.menu.style.left = "100px";
        this.menu.style.top = "20px";
        this.menu.style.position = "absolute"; // 为新创建的DIV指定绝对定位
        this.menu.style.width = 100 + "px";
      }
    },
    handleClose(done) {
      done();
      this.resetForm("createDate");
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    NodeEdit(n, d) {
      //编辑节点
      this.titName = "修改节点";
      this.dialogVisible = true;
      this.createDate.id = d.id;
      let fcn = null;
      if (this.activeName == "城市") {
        fcn = cityDetail;
      } else {
        fcn = getCustomById;
      }
      fcn({ id: d.id }).then((res) => {
        if (res.success) {
          this.createDate.name = res.result.name;
          this.createDate.parentId = res.result.parentId;
          this.createDate.type = res.result.type;
          this.createDate.version = res.result.version;
        }
      });
    },
    NodeDel(n, d) {
      //删除节点
      if (d.children && d.children.length !== 0) {
        this.$message.error("此节点有子级，不可删除！");
        return false;
      } else {
        //新增节点可直接删除，已存在的节点要二次确认
        //删除操作
        let DelFun = () => {
          let fcn = null;
          if (this.activeName == "城市") {
            fcn = cityDelete;
          } else {
            fcn = deleteCustom;
          }
          fcn({ id: d.id }).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.loadList(this.activeName);
            }
          });
        };
        //二次确认
        let ConfirmFun = () => {
          this.$confirm("是否删除 " + d.name + "？", "提示", {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              DelFun();
            })
            .catch(() => {});
        };
        //判断是否是新增节点
        ConfirmFun();
      }
    },
    NodeAdd(n, d) {
      //新增数据
      this.titName = "新增节点";
      this.dialogVisible = true;
      this.createDate.id = "";
      this.createDate.parentId = d.id;
      this.createDate.type = this.activeName;
    },
    loadList(types) {
      this.rowLoading = true;
      let fcn = null;
      if (types == "城市") {
        fcn = cityTree;
      } else {
        fcn = getCommonColumnList;
      }
      fcn({ type: types }).then((res) => {
        this.rowLoading = false;
        if (res.success) {
          this.setTree = res.result;
        }
      });
    },
  },
  mounted() {
    this.menu = document.querySelector("#rightClickMenu");
  },
  created() {
    this.loadList(this.activeName);
  },
};
</script>
<style lang="scss">
.customTag {
  position: relative;
  .commonBtn {
    position: absolute;
    right: 27px;
    top: 0;
  }
  .is-current {
    background-color: #f6f6f6;
  }
  .el-tabs__item {
    line-height: normal;
    height: 36px;
  }
  .el-tabs__nav-scroll {
    padding: 0 15px;
  }
}
.tree-box {
  .tip {
    margin: 0 15px 15px 15px;
  }
  .defaultColor {
    border: 1px solid #ffdddc;
    padding: 10px 20px;
    color: #cc6666 !important;
    background-color: #fff5f4;
    margin: 0 0 10px;
    &:hover {
      color: #cc6666 !important;
    }
  }
}
/*************************标签鼠标右击页面样式******************************/
.el-menu-vertical {
  box-shadow: 0 0 10px 6px rgba(51, 51, 51, 0.1);
  padding: 10px 0;
  z-index: 100;
  li {
    padding-inline-start: 20px !important;
  }
}
.el-tree-node {
  position: relative;
}
.el-tree-node > .el-tree-node__children {
  overflow: inherit;
}
.menuItem {
  height: 40px;
  line-height: 40px;
  text-align: left;
  background-color: #fff;
  font-size: 12px;
}
.menuItem:hover {
  background-color: #f6f6f6;
}
</style>
<style lang="scss" scoped>
.box-title {
  padding: 15px 0;
}
</style>
